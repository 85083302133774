import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Country from './components/Country';
import State from './components/State';
import StateMenu from './components/StateMenu';
import About from './components/About';
import Donate from './components/Donate';
import Navbar from './components/Navbar';
import BottomNavBar from './components/BottomNavBar';
import "leaflet/dist/leaflet.css";

import './App.css';
import fireLogo from './pictures/corner_fire.png';

const App = () => {
  console.log("Maintenance notice is rendering");

  return (
    <Router>
      <div id="root">
        <header>
          <h1 className="header">Welcome to the Wildfire Condition Prediction Application</h1>
          <img src={fireLogo} alt="Logo" className="top-right-image" />
          <Navbar />
        </header>

        <div id="maintenance-notice" style={{ padding: '1em', backgroundColor: '#f8d7da', color: '#721c24', textAlign: 'center', marginBottom: '1em' }}>
          This web application is no longer maintained or updated, due to the creator (me) being committed to personal & career projects.
          Please feel free to reach out to me at my email contained in the "About Me" page. Thank you for visiting!
        </div>

        <main style={{ flex: 1, overflow: 'auto' }}>
          <Routes>
            <Route path="/about" element={<About />} />
            <Route path="/donate" element={<Donate />} />
            <Route path="/country" element={<Country />} />
            <Route path="/stateMenu" element={<StateMenu />} />
            <Route path="/" element={<Country />} />
          </Routes>
        </main>
        <BottomNavBar />
      </div>
    </Router>
  );
};

export default App;


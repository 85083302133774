import React, { useState, useEffect } from 'react';
import { MapContainer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import StateMenu from './StateMenu';

const Country = () => {
  const [mapData, setMapData] = useState(null);
  const [borderData, setBorderData] = useState(null);

  useEffect(() => {
    fetch('https://wildfireconditionprediction.com/api/state_fire_map')
      .then(response => response.json())
      .then(data => {
        console.log('state_fire_map data:', data);
        setMapData(data);
      });
    fetch('https://wildfireconditionprediction.com/api/country_fire_map')
      .then(response => response.json())
      .then(data => {
        console.log('country_fire_map data:', data);
        setBorderData(data);
      })
      .catch(error => console.error('Fetch error:', error));
  }, []);

  if (!mapData) {
    return <p style={{ color: '#87888d' }}>Loading map data...</p>;
  }

  const getColor = (probability) => {
   probability = Math.min(Math.max(probability, 0), 1);

  // Interpolate the color
  const red = Math.floor(255 * probability);
  const green = Math.floor(255 * (1 - probability));
  const blue = 0;

  return `rgb(${red}, ${green}, ${blue})`;
};


const getRiskLevel = (probability) => {
  if (probability > 0.80) {
    return 'VERY HIGH';
  }
    else if (probability > 0.70) {
    return 'HIGH';
  } else if (probability > 0.60) {
    return 'MODERATE';
  } else if (probability > 0.50) {
    return 'LOW';
  } else {
    return 'VERY LOW';
  }
};

  const onEachFeature = (feature, layer) => {
    if (feature.properties) {
      const probability = feature.properties.wildfire_probability || 0;
     let  risk = getRiskLevel(probability);
      layer.bindPopup(
        `COUNTY FIPS CODE: ${feature.properties.COUNTYFP} <br />
         STATE FIPS CODE: ${feature.properties.STATEFP} <br />
         COUNTY NAME: ${feature.properties.NAME} <br />
         STATE NAME: ${feature.properties.STATE_NAME} <br />
         WILDFIRE CONDITIONS: ${risk} (${(probability * 100).toFixed(2)}%)`
      );
    }
  };

  const stateStyle = (feature) => {
    const probability = feature.properties.wildfire_probability || 0;
    return {
      color: 'black',
      weight: 0.5,
      opacity: 1,
      fillColor: getColor(probability),
      fillOpacity: 0.75
    };
  };

  const countryStyle = {
    color: 'black',
    weight: 2,
    opacity: 1,
    fillColor: 'transparent',
    fillOpacity: 0.75
  };

  return (
    <div style={{ height: '100vh', overflowY: 'auto' }}>

      <MapContainer
        center={[37.8, -96]}
        zoom={4}
        style={{ height: '80vh', width: '100%' }}
        scrollWheelZoom={true}
        doubleClickZoom={true}
        zoomControl={true}
      >
        {borderData && <GeoJSON data={borderData} style={countryStyle} onEachFeature={onEachFeature} />}
        {mapData && <GeoJSON data={mapData} style={stateStyle} onEachFeature={onEachFeature} />}
      </MapContainer>

    </div>
  );
};

export default Country;

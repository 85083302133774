import React from 'react';
import './About.css';

const About = () => {
  return (
    <div style={{ padding: '1rem' }}>
      <h1>About Me</h1>
      <h3 className="about">Hi, I’m Wyatt!</h3>
      <p className="about" >
        I’m a recent graduate from WGU, with a bachelor's in Computer Science! 
        This project is an extension of my capstone project and has taken months to put together.
        It features a variety of machine learning algorithms, including Random Forest, Gradient Boosting, and an LSTM Neural Network Model. 
        The application is built using Flask for the backend, which handles API requests and serves the machine learning models, 
        while React is used for the frontend to create an interactive user interface.<br /><br />
        
        The backend is powered by Flask, a lightweight WSGI web application framework in Python. 
        Flask was chosen for its simplicity and flexibility, allowing rapid development and easy integration with machine learning models. 
        The models are trained and deployed within the Flask framework, making real-time predictions accessible through API endpoints.<br /><br />
        
        For the frontend, React is used to build a dynamic and responsive user interface. 
        The state management and hooks provided by React ensure smooth data flow and efficient rendering of components based on user interactions.<br /><br />
        
        The application is hosted on an IONOS web-server instance, where Nginx serves as a reverse proxy to handle incoming requests and direct them to the Flask backend 
        running on Gunicorn. This setup provides a scalable and robust environment to ensure the application can handle multiple requests efficiently.<br /><br />
        
        Disclaimer: This project is for personal and educational purposes only. The predictions and data provided by this application should not be used 
        for any critical decision-making or relied upon in real-world scenarios. The application is a demonstration of my skills and knowledge in web development 
        and machine learning, and it is not intended for commercial or professional use.<br /><br />
        
        If you'd like to reach me, I’m available at: <a href="mailto:wdurbi5@gmail.com">wdurbi5@gmail.com</a>, or you can check out my LinkedIn at:
        <a href="https://www.linkedin.com/in/wyatt-durbin-ba7a7522a" target="_blank" rel="noopener noreferrer">https://www.linkedin.com/in/wyatt-durbin-ba7a7522a</a>
      </p>
    </div>
  );
};

export default About;


import React, { useState, useEffect } from 'react';
import { MapContainer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './State.css';
import County from './County';
import BottomNavbar from './BottomNavBar';

const State = ({ stateData }) => {
  const [selectedCounty, setSelectedCounty] = useState(null);

  if (!stateData) {
    return null;
  }

const getColor = (probability) => {
   probability = Math.min(Math.max(probability, 0), 1);

  // Interpolate the color
  const red = Math.floor(255 * probability);
  const green = Math.floor(255 * (1 - probability));
  const blue = 0;

  return `rgb(${red}, ${green}, ${blue})`;
};


const getRiskLevel = (probability) => {
  if (probability > 0.80) {
    return 'VERY HIGH';
  }
    else if (probability > 0.70) {
    return 'HIGH';
  } else if (probability > 0.60) {
    return 'MODERATE';
  } else if (probability > 0.50) {
    return 'LOW';
  } else {
    return 'VERY LOW';
  }
};
  const getCenter = (stateData) => {
    let x = 0.0;
    let y = 0.0;
    let z = 0.0;
    let total = 0;

    stateData.features.forEach((feature) => {
      const coordinates = feature.geometry.type === 'Polygon'
        ? feature.geometry.coordinates[0]
        : feature.geometry.coordinates[0][0];

      coordinates.forEach((coordinate) => {
        const longitude = coordinate[0];
        const latitude = coordinate[1];

        const latRad = latitude * Math.PI / 180;
        const lonRad = longitude * Math.PI / 180;

        x += Math.cos(latRad) * Math.cos(lonRad);
        y += Math.cos(latRad) * Math.sin(lonRad);
        z += Math.sin(latRad);

        total += 1;
      });
    });

    x = x / total;
    y = y / total;
    z = z / total;

    const centralLongitude = Math.atan2(y, x);
    const centralSquareRoot = Math.sqrt(x * x + y * y);
    const centralLatitude = Math.atan2(z, centralSquareRoot);

    return [centralLatitude * 180 / Math.PI, centralLongitude * 180 / Math.PI];
  };

  const center = getCenter(stateData);

  const stateStyle = (feature) => {
  const probability = feature.properties.wildfire_probability || 0;
    return {
      color: 'black',
      weight: 0.5,
      opacity: 1,
      fillColor: getColor(probability),
      fillOpacity: 0.75,
    };
  };

  const onEachFeature = (feature, layer) => {
    if (feature.properties) {
      layer.on({
        click: () => {
          setSelectedCounty(feature.properties);
        },
      });
    }
  };



if (selectedCounty) {
    return <County countyData={selectedCounty} goBack={() => setSelectedCounty(null)} />;}

  return (
    <div className="state-container">
      <h2> Select a County </h2>
      <p className="county-instructions"> (Counties can be selected by clicking on them!) </p>
      <MapContainer
        center={center}
        zoom={6}

        scrollWheelZoom={false}
        doubleClickZoom={false}
        zoomControl={true}
      >

        <GeoJSON data={stateData} style={stateStyle} onEachFeature={onEachFeature} />

      </MapContainer>



    </div>

  );
};

export default State;

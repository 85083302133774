import React from 'react';
import { Link } from 'react-router-dom'
import './NavBar.css';




const BottomNavBar = () => {
  return (
    <nav className="navbar">

      <div className="navbar-middle">
        <Link to="/country">Country View</Link>
        <Link to="/stateMenu">State View</Link>
      </div>

    </nav>
  );
};

export default BottomNavBar;


import React, { useState, useEffect } from 'react';
//import { MapContainer, GeoJSON, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import State from './State';

const  StateMenu = () =>  {
    //const [stateData, setStateData] = useState([]);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedStateData, setSelectedStateData] = useState(null);
    const [stateNames, setStateNames] = useState([]);
    const [loading, setLoading] = useState([]);

    useEffect(() => {
        const states = ["Alabama", "Arkansas", "Arizona", "California",
               "Colorado", "Connecticut", "Delaware", "Florida", "Georgia",
               "Iowa", "Idaho", "Illinois", "Indiana", "Kansas", "Kentucky",
               "Louisiana", "Massachusetts", "Maryland", "Maine",
               "Michigan", "Minnesota", "Missouri", "Mississippi",
               "Montana", "North Carolina", "North Dakota", "Nebraska",
               "New Hampshire", "New Jersey", "New Mexico", "Nevada",
               "New York", "Ohio", "Oklahoma", "Oregon", "Pennsylvania",
               "Rhode Island", "South Carolina", "South Dakota",
               "Tennessee", "Texas", "Utah", "Virginia", "Vermont",
               "Washington", "Wisconsin", "West Virginia", "Wyoming"]

        setStateNames(states);
    }, []);


   const handleStateChange = (event) => {
        setSelectedState(event.target.value);
    };

       useEffect(() => {
        if (selectedState && selectedState !== 'null') {
            setLoading(true);
            fetch(`https://wildfireconditionprediction.com/api/state_data/${selectedState}`)
                .then(response => response.json())
                .then(data => {
                    setSelectedStateData(data);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Fetch error:', error);
                    setLoading(false);
                });
        }
    }, [selectedState]);

    return (
        //<div style={{ padding: '20px', background: '#333', background: 'transparent'}}>
        <div className="stateMenuItem">
            <h2>Select a State</h2>
            <select onChange={handleStateChange}>
                <option value="">Select a state</option>
                {stateNames.map((state, index) => (
                    <option key={index} value={state}>{state}</option>
                ))}
            </select>
                {!loading && selectedState && <State key={selectedState} stateData={selectedStateData} />}
        </div>
    );
}
export default StateMenu;

import React from 'react';
import './BottomNavBar.css';

const BottomNavbar = () => {
  return (
    <footer className="bottom-navbar">
      <div>
        <a href="/about">About Me</a>
        <a href="/donate">Donations</a>
      </div>
    </footer>
  );
};

export default BottomNavbar;

import React, { useEffect, useState } from 'react';
import BottomNavBar from './BottomNavBar';
import './County.css';
function County({ countyData, goBack }) {
    const [averageWeatherData, setAverageWeatherData] = useState(null);
    const [weatherRecap, setWeatherRecap] = useState(null);

    useEffect(() => {
        if (countyData && countyData.NAME && countyData.STATE_NAME) {
            fetch(`https://wildfireconditionprediction.com/api/weather_data/${countyData.NAME}/${countyData.STATE_NAME}`)
                .then(response => response.json())
                .then(data => setAverageWeatherData(data))
                .catch(error => console.error('Error fetching data:', error));
        }
    }, [countyData]);




        useEffect(() => {
        if (countyData && countyData.NAME && countyData.STATE_NAME) {
            const input = `${countyData.NAME}, ${countyData.STATE_NAME}`;
            fetch('https://wildfireconditionprediction.com/api/weather_county_recap', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ input }),
            })
                .then(response => response.json())
                .then(data => setWeatherRecap(data.response))
                .catch(error => console.error('Error fetching  response:', error));
        }
    }, [countyData]);
return (
    <div className="county-container">
        <div>
        <button onClick={goBack}>Back to State</button>
        <h2>{countyData.NAME} County, {countyData.STATE_NAME}</h2>
        {averageWeatherData ? (

            <div className='weather-stats'>
                <p>Average Max Temperature: {averageWeatherData['Average max temperature']}</p>
                <p>Average Minimum Temperature: {averageWeatherData['Average minimum temperature']}</p>
                <p>Average Precipitation: {averageWeatherData['Average precipitation']}</p>
                <p>Average Max Wind Speed: {averageWeatherData['Average max wind speed']}</p>
                <p>Average Max Wind Gust Speed: {averageWeatherData['Average max wind gust speed']}</p>
                <p>Average Surface Pressure: {averageWeatherData['Average surface pressure']}</p>
                <p>Average Humidity: {averageWeatherData['Average humidity']}</p>
                <p>Average Dew Point: {averageWeatherData['Average dew point']}</p>
            </div>
        )  : (
                <p className='weather-recap'>Loading average weather data...</p>
            )}
            {weatherRecap ? (
                <div>
                    <h3>Weather Recap:</h3>
                    <p className='weather-recap'>{weatherRecap}</p>
                </div>
            ) : (
                <p className='weather-recap'>Loading weather recap...</p>
            )}

            </div>

        </div>


);
}

export default County;

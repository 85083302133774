import React from 'react';
import PayPalButton from './PayPalButton';
import './Donate.css'

const Donate = () => {
     return (
        <div className="App">
            <h1>Donate with PayPal</h1>
             <h3 className="donate"> Thanks for your time spent visiting my web-app! </h3>
		<p className="donate"> 
                Developing, maintaining, and continuing work on this project comes with costs (especially API calls!).<br />
                So far, I’ve covered these costs out of pocket, but if you’re willing to donate to help support me and my project, every bit helps.<br />
                Your generosity will contribute to the ongoing development and improvement of this web-app.<br /> <br />
                Thank you for your support!
            </p>
             <a href="https://www.paypal.com/donate/?hosted_button_id=J2UN67M4YDUGY" target="_blank">
        <button>Donate via PayPal</button>
    </a>
        </div>
    );
}

export default Donate;
